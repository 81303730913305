$state_green: #4caf50;
$smallbreak: 768px;
$midbreak: 1200px;
$largebreak: 1400px;

$color1: #0d2432;
$color2: #37526d;
$color3: #6768ab;
$color4: #ac8188;
$color5: #feb17d;

$hover: #0d243233;

* {
  box-sizing: border-box;
}

html {
  background-color: #f4f5f6;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;

  body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: auto;

    a {
      color: $color3;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    .false {
      color: #bbb;
      font-style: italic;
    }

    #root {
      box-sizing: border-box;

      [data-amplify-router] {
        border: 0px;

        @media only screen and (max-width: $smallbreak) {
          margin: 0 1rem;
        }
      }

      .header_bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-right: 1rem;
      }

      #breadcrumbs li,
      #breadcrumbs a,
      #breadcrumbs p {
        font-size: 0.75rem;
        text-transform: uppercase;

        @media only screen and (max-width: $smallbreak) {
          font-size: 0.75rem;
        }
      }

      .root_container {
        display: flex;
        flex-direction: row;
        justify-content: stretch;
        gap: 1rem;
        overflow: hidden;
        flex: 1;

        .odd {
          background: #fff;
        }
        .even {
          background: #fafafa;
        }

        .MuiDataGrid-footerContainer,
        .table__header {
          background: #fff;
          color: #000;
        }

        .table_row___selector {
          color: #2277dd;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        .grid_head {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 0 0 0 1rem;
          gap: 1rem;
          background: #fff;
          border: 1px solid #ddd;
          position: sticky;
          top: 0;
          z-index: 1000;

          .grid_head___actions {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 1rem;

            @media only screen and (max-width: $smallbreak) {
              gap: 0.5rem;

              button {
                padding: 0.25rem 0.5rem;
                font-size: 0.65rem;

                &:disabled {
                  display: none;
                }

                span {
                  font-size: inherit;
                }
              }
            }
          }

          .grid_head___badge {
            height: 2rem;
            width: 2rem;
          }

          .grid_head___title {
            h3 {
              text-transform: uppercase;
              color: #666;
              font-size: 0.65rem;
              font-weight: 400;
            }
            h4 {
              color: #666;
              font-size: 0.65rem;
              font-weight: 400;
            }
            h2 {
              font-size: 1rem;
              margin: 0.25rem 0;
              font-weight: 500;

              .grid_head___env {
                text-transform: uppercase;
                font-size: 0.65rem;
                padding-left: 0.5rem;
              }

              a {
                color: #111;
                text-decoration: none;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        .grid_row {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          min-height: 35px;
          border: 1px solid #eee;
          padding: 0.25rem;
          background: #fafafa;
        }

        .grid_main___val {
          color: $color1;
          text-align: right;
        }

        .grid_main___icon {
          height: 0.875rem;
          width: 0.875rem;
          vertical-align: middle;

          &.right {
            margin-right: 0.5rem;
          }
          &.left {
            margin-right: 0.5rem;
          }
        }

        .main_nav {
          transition: all 0.15s ease-in-out;
          overflow: hidden;
          border-radius: 0;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          justify-content: stretch;
          gap: 1rem;

          &.drawer_open {
            width: 100%;
            // display: flex;
            margin: 0;
            display: block;
            overflow-x: hidden;
            overflow-y: scroll;

            @media only screen and (max-width: $smallbreak) {
              // margin: 1rem;
              display: block;
              overflow-x: hidden;
              overflow-y: scroll;
            }

            .main_nav___container {
              display: flex;
              flex-direction: column;
            }
          }

          &.drawer_closed {
            display: none;
            margin: 1rem 0;
            padding: 0;
            background-color: #fff;
            -webkit-box-shadow: 0 0 0 0 #ffffff00;
            box-shadow: 0 0 0 0 #ffffff00;

            @media only screen and (max-width: $smallbreak) {
              opacity: 0;
              width: 100%;
              display: none;
            }
          }

          .main_nav___close {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 0 1rem 1rem 0;
            align-items: center;
          }

          .main_nav___main {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: stretch;
            gap: 1rem;

            overflow: scroll;
            margin: -1px 0 0 0;
            background-color: #fff;
            border-left: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
            border-right: 1px solid #ddd;

            &:not(:first-child) {
              border-top: 1px solid #ddd;
            }

            @media only screen and (max-width: $midbreak) {
              display: block;
              padding: 0 1rem 1rem;
            }

            .grid_main___block {
              min-width: 30%;
              // max-width: 33%;
              flex: 1;
              padding: 0 1rem 1rem;
              display: flex;
              flex-direction: column;
              gap: 0.25rem;

              @media only screen and (max-width: $midbreak) {
                min-width: 100%;
                max-width: 100%;
                flex-direction: column;
                padding: 0;
                // min-width: 48%;
                // margin-bottom: 1rem;
              }

              @media only screen and (max-width: $smallbreak) {
                min-width: 100%;
                max-width: 100%;
                flex-direction: column;
                padding: 0;
              }
            }

            .grid_main___head {
              display: block;
              margin-top: 1rem;
              width: 100%;
              padding: 0;
              margin-bottom: 0rem;
              font-weight: 600;
              // border-bottom: 1px solid #ccc;
            }
          }

          .main_nav___container {
            // overflow: hidden;
            margin-bottom: 1rem;

            .grid_foot {
              display: none;
            }
          }
        }

        .main_container {
          transition: all 0.15s ease-in-out;
          width: 100%;
          overflow: hidden;
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: stretch;

          &.drawer_open {
            display: none;

            .grid_item {
              width: calc(50% - 0.5rem);

              @media only screen and (min-width: $largebreak) {
                width: calc(33% - 1rem);
              }

              @media only screen and (max-width: $midbreak) {
                width: 100%;
              }

              @media only screen and (max-width: $smallbreak) {
                min-width: unset;
                width: 100%;
              }

              .grid_foot {
                display: flex;
                justify-content: center;
              }
            }
          }

          &.drawer_closed {
            width: 100%;

            @media only screen and (max-width: $smallbreak) {
              display: flex;
            }

            .grid_item {
              min-width: 12rem;
              width: calc(33% - 0.5rem);

              @media only screen and (min-width: $largebreak) {
                width: calc(25% - 1rem);
              }

              @media only screen and (max-width: $midbreak) {
                width: calc(50% - 1rem);
              }
              @media only screen and (max-width: $smallbreak) {
                min-width: unset;
                width: 100%;
              }

              .grid_foot {
                display: flex;
                justify-content: center;
              }
            }
          }

          .grid_date {
            max-width: 90rem;
            margin: 0 auto;
          }

          .grid_container {
            flex: 1;
            overflow: scroll;
            padding: 0;
            margin: 0 auto;

            .MuiDataGrid-columnHeaders {
              border-radius: 0;
            }
          }
        }
        .grid_item {
          background: #fff;
          cursor: pointer;
          border-radius: 0.5rem;
          display: flex;
          justify-content: flex-start;
          box-sizing: border-box;

          &.alert {
            border: 1px solid #f00;
          }

          &.active:not(.alert) {
            border: 0.25rem solid $hover;
          }

          &.inactive:not(.alert) {
            border: 0.25rem solid transparent;
          }

          &:hover {
            -webkit-box-shadow: 0 0 0.4rem 0.4rem $hover;
            box-shadow: 0 0 0.4rem 0.4rem $hover;
          }

          .grid_main___container {
            padding: 1rem;
          }

          .grid_main___accordion {
            width: 100%;
            border: 0;

            &::before {
              display: none;
            }
          }

          .grid_chip {
            min-width: 20%;
            background-color: #fff;
          }

          .grid_chip___default {
            min-width: 20%;
            border: 1px solid #ccc;
            color: #666;
            background-color: #eee;
          }

          .grid_chip___premium {
            min-width: 20%;
            border: 1px solid skyblue;
            color: #666;
            background-color: aliceblue;
          }

          .grid_main {
            display: flex;
            flex-direction: row;
            gap: 0.25rem;
            align-items: center;
            flex-wrap: wrap;

            .grid_main___head {
              margin-top: 1rem;
              width: 100%;
              padding: 0;
              margin-bottom: 1rem;
            }
          }
        }
      }
    }

    @media only screen and (max-width: $smallbreak) {
      box-sizing: border-box;
    }

    @media only screen and (max-width: $smallbreak) {
      #root .header_bar {
        padding-right: 0;
      }
    }
  }
}
